import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RpApi } from "../util/Api";
import revplanLogo from "../image/RevisionPlanLogo32.png";

const DownloadPlan = () => {
    
    const [urlParams] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [surname, setSurname] = useState("");
    const [planId, setPlanId] = useState("");
    const [outputType, setOutputType] = useState("2");
    const [toPdf, setToPdf] = useState("0");
    const [iframeSrc, setIframeSrc] = useState("");
    const [reqCnt, setReqCnt] = useState(0);

    const setUrlParams = () => {
        for (let [key, value] of searchParams.entries()) {
            urlParams[key] = value;
        }
        setSearchParams("");
        if(urlParams.rpid !== undefined && urlParams.rpid.length > 0) {
            setPlanId(urlParams.rpid);
        }
    };
 
    const validateParams = () => {
        if(surname === undefined || surname.length === 0 ) {
            alert('Please enter your Surname');
            return false;
        } else if(planId === undefined || planId.length < 10 || planId.split('-').length != 5) {
            alert('Please enter a Valid Plan ID with 4 \'-\' separators');
            return false;
        }
        return true;
    }

    const handleRequestDownload = () => {

        if(!validateParams()) {
            return;
        }

        var href = RpApi.getBaseServer() + '/' +
            "revplanAjaxGeneric?action=doDownloadRevisionPlanFromWeb" + 
            "&surname=" + surname +
            "&toPdf=" + toPdf + 
            "&rpid=" + planId + 
            "&outMode=" + outputType + 
            "rq=" + reqCnt;
        
        console.log(href);
        setIframeSrc(href); 
        setTimeout(() => setReqCnt(reqCnt + 1), 15000);  
        if(reqCnt > 100) {
            setReqCnt(0);
        } 
    }

    useEffect(() => {
        setUrlParams();
    }, []);

    return (
        <div className="section">
            <div className="container">
                <div className="row full-height justify-content-center">
                    <div className="col-12 text-center align-self-center py-5">
                        <div className="section pb-5 pt-5 pt-sm-2 text-center">
                            <div className="card-3d-wrap mx-auto">
                                <div className="card-3d-wrapper">
                                    <div className="card-front">
                                        <div className="center-wrap">
                                            <div className="section text-center">
                                                <h4 className="mb-4 pb-3">
                                                    Download Plan &nbsp;
                                                    <img src={revplanLogo} />
                                                </h4>
                                                <div className="form-group">
                                                    <input
                                                        type="surname"
                                                        name="surname"
                                                        className="form-style"
                                                        placeholder="Your Surname"
                                                        id="surname"
                                                        autoComplete="off"
                                                        value={surname}
                                                        onChange={(e) => {
                                                            setSurname(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <i className="input-icon uil uil-at"></i>
                                                </div>
                                                <div className="form-group mt-2">
                                                    <input
                                                        type="planid"
                                                        name="planid"
                                                        className="form-style text-uppercase"
                                                        placeholder="RevisionPlan ID"
                                                        id="planid"
                                                        autoComplete="off"
                                                        value={planId}
                                                        onChange={(e) => {
                                                            setPlanId(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <i className="input-icon uil uil-lock-alt"></i>
                                                </div>
                                                <div className="form-group mt-2">
                                                    <select
                                                        type="outputType"
                                                        name="outputType"
                                                        id="outputType"
                                                        className="form-style"
                                                        value={outputType}
                                                        onChange={(e) => {
                                                            setOutputType(
                                                                e.target.value
                                                            );
                                                        }}
                                                            >
                                                            <option value="0">Content: &nbsp;Revision Tasks Only</option>
                                                            <option value="1">Content: &nbsp;Timetable Only</option>
                                                            <option value="2">Content: &nbsp;Revision Tasks &amp; Timetable</option>
                                                    </select>
                                                    <select 
                                                        type="toPdf" 
                                                        name="toPdf" 
                                                        id="toPdf"
                                                        className="form-style mt-2"
                                                        value={toPdf}
                                                        onChange={(e) => {
                                                            setToPdf(
                                                                e.target.value
                                                            );
                                                        }}
                                                            >
                                                        <option value="0">Format: &nbsp;&nbsp;&nbsp;Docx</option>
                                                        <option value="1">Format: &nbsp;&nbsp;&nbsp;Pdf</option>
                                                    </select>
                                                </div>
                                                <a
                                                    href="#"
                                                    className="btn mt-4"
                                                    onClick={
                                                        handleRequestDownload
                                                    }
                                                >
                                                    submit
                                                </a>
                                                {/* <p className="mb-0 mt-4 text-center">
                                                    <Link
                                                        className="link"
                                                        to="/"
                                                    >
                                                        back to home
                                                    </Link>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <iframe id="eventIFrame" name="eventIFrame" style={{ display: "none" }} src={iframeSrc} frameBorder="0" height="0px" width="0px"></iframe>
        </div>
    );
};

export default DownloadPlan;
