import { DebugMode } from "./constants";

const makeRpApi = () => {
    let api = {
        baseServer: null,

        ////////////////////////////////////////////

        initialize: function () {
            console.log("RpApi - initialise - isDebugMode: " + DebugMode);
            this.baseServer =
                DebugMode === true
                    ? "http://localhost:8080/RevPlanWeb"
                    : "https://www.revisionplan.com/rp";
            console.log("RpApi - baseServer: " + this.baseServer);
        },

        getBaseServer: function () {
            return this.baseServer;
        },

        call: async function (endpoint, params, callback) {
            console.log("RpApi - call - isDebugMode: " + DebugMode);
            let url =
                this.baseServer +
                "/" +
                endpoint +
                (params === undefined || params.length === null
                    ? ""
                    : "?" + params);
            console.log("RpApi - call -  url: " + url);

            let jsonResponse = {
                data: undefined,
                error: undefined,
                status: undefined,
            };

            try {
                let response = await fetch(`${url}`, {
                    method: "GET",
                    //mode: "no-cors",
                    headers: {
                        Accept: "application/html",
                        //Accept: "application/json",
                        //Authorization: "API_KEY",
                    },
                    signal: AbortSignal.timeout(60000),
                });

                jsonResponse.status = response.status;

                if (response.status === 200) {
                    // || response.status === 0) {  //0 == CORS Override with no Status
                    //const responseObj = await response.json();
                    jsonResponse.data = await response.text();
                } else if (response.status === 400) {
                    jsonResponse.error = "Invalid App Permissions";
                } else if (response.status === 401) {
                    jsonResponse.error = "Acces denied";
                } else {
                    jsonResponse.error = "Unable to access API";
                }
            } catch (err) {
                console.info("RpApi - call - Error:" + err.message);
                jsonResponse.status = 0;
                jsonResponse.error = err.message;
            }
            callback(jsonResponse);
        },
    };

    return api;
};

export const RpApi = makeRpApi();
RpApi.initialize();
