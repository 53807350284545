import { createBrowserRouter } from "react-router-dom";

import Error404 from "./Error404";
import Login from "./Login";
import NewPassword from "./NewPassword";
import DownloadPlan from "./DownloadPlan";
import { Context } from "../util/constants";

export const Router = createBrowserRouter([
    {
        errorElement: <Error404 />,
        children: [
            {
                path: Context + "/",
                children: [
                    { path: "", element: <Login /> },
                    { path: "newpassword", element: <NewPassword /> },
                    { path: "myplan", element: <DownloadPlan /> },
                ],
            },
        ],
    },
]);
