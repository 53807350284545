import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RpApi } from "../util/Api";
import { DebugMode } from "../util/constants";
import revplanLogo from "../image/RevisionPlanLogo32.png";

const getLoginParams = (userid, pswd) => {
    var rqsthref = window.location.href;
    var href = window.location.href;
    try {
        href = window.top.location.href;
        href = href.split("?")[0]; //Ignore Parameters
        rqsthref = rqsthref.split("?")[0]; //Ignore Parameters
    } catch (er) {
        href = "Location - Permission Denied";
    }

    href = href.split("#")[0];
    rqsthref = rqsthref.split("#")[0];

    var paramList = "userid=" + userid;
    paramList += "&pswd=" + pswd;
    paramList += "&info_sw=" + window.screen.width;
    paramList += "&info_sh=" + window.screen.height;
    paramList += "&info_ck=" + (navigator.cookieEnabled ? "true" : "false");
    paramList += "&info_tphrf=" + href;
    paramList += "&info_rqhrf=" + rqsthref;

    var params = "action=reactLogin";
    params += "&ajaxFormSubmit=1";
    params += "&userid=" + userid;
    params += "&pswd=" + pswd;

    return [params, paramList];
};

const Login = () => {
    const [urlParams] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [resetMode, setResetMode] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [resetEmail, setResetEmail] = useState("");
    const [resetMessage, setResetMessage] = useState("");

    const checkHttpsUrl = () => {
        if (DebugMode === false && window.location.protocol === "http:") {
            window.location.href = window.location.href.replace(
                /^http:/,
                "https:"
            );
        }
    };

    const setUrlParams = () => {
        for (let [key, value] of searchParams.entries()) {
            urlParams[key] = value;
        }
        setSearchParams("");
        if (urlParams.reset !== undefined && urlParams.reset === "1") {
            handleLoginSwitch(1);
        }
    };

    const validateEmail = () => {
        setResetMessage("");
        let regex =
            /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        let valid =
            resetEmail !== undefined &&
            resetEmail.length !== 0 &&
            regex.test(resetEmail);
        if (!valid) {
            setResetMessage("Invalid Email");
        }
        return valid;
    };

    const handleLoginSwitch = (showReset) => {
        setResetMode(showReset ? "checked" : "");
        setLoginError("");
        setResetMessage("");
    };

    const handleResetEmailAndHint = (params) => {
        if (!validateEmail()) {
            return;
        }
        RpApi.call("ajaxGeneric", params, (jsonResp) => {
            if (jsonResp.status !== 200) {
                console.log(
                    "Login - handleResetEmailHint (" +
                        params +
                        ") - Error:" +
                        jsonResp.status +
                        " : " +
                        jsonResp.error
                );
                setResetMessage(jsonResp.error);
            } else {
                let json = JSON.parse(jsonResp.data);
                setResetMessage(json.error.length > 0 ? json.error : json.data);
            }
        });
    };

    const handleResetEmail = () => {
        handleResetEmailAndHint("action=passwordReminder&email=" + resetEmail);
    };

    const handleResetHint = () => {
        handleResetEmailAndHint("action=passwordHint&email=" + resetEmail);
    };

    const handleLoginFormSubmit = () => {
        setLoginError("");
        let params = getLoginParams(username, password);
        RpApi.call("revplanAjaxGeneric", params[0], (jsonResp) => {
            if (jsonResp.status !== 200) {
                console.log(
                    "Login - submitLoginDetails - Error:" +
                        jsonResp.status +
                        " : " +
                        jsonResp.error
                );
                setLoginError(jsonResp.error);
            } else {
                let json = JSON.parse(jsonResp.data);
                if (!json.error.length) {
                    window.top.location = json.data + "&" + params[1];
                } else {
                    setLoginError(json.error);
                }
            }
        });
    };

    useEffect(() => {
        //checkHttpsUrl();
        setUrlParams();
    }, []);

    return (
        <div className="section">
            <div className="container">
                <div className="row full-height justify-content-center">
                    <div className="col-12 text-center align-self-center py-5">
                        <div className="section pb-5 pt-5 pt-sm-2 text-center">
                            <input
                                style={{ display: "none" }}
                                className="checkbox"
                                type="checkbox"
                                id="reg-log"
                                name="reg-log"
                                checked={resetMode}
                                readOnly
                            />
                            <div className="card-3d-wrap mx-auto">
                                <div className="card-3d-wrapper">
                                    <div className="card-front">
                                        <div className="center-wrap">
                                            <div className="section text-center">
                                                <h4 className="mb-4 pb-3">
                                                    Log In &nbsp;
                                                    <img src={revplanLogo} />
                                                </h4>
                                                <div className="form-group">
                                                    <input
                                                        type="username"
                                                        name="logusername"
                                                        className="form-style"
                                                        placeholder="Your Username"
                                                        id="logusername"
                                                        autoComplete="off"
                                                        value={username}
                                                        onChange={(e) => {
                                                            setLoginError("");
                                                            setUsername(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <i className="input-icon uil uil-at"></i>
                                                </div>
                                                <div className="form-group mt-2">
                                                    <input
                                                        type="password"
                                                        name="logpass"
                                                        className="form-style"
                                                        placeholder="Your Password"
                                                        id="logpass"
                                                        autoComplete="off"
                                                        value={password}
                                                        onChange={(e) => {
                                                            setLoginError("");
                                                            setPassword(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <i className="input-icon uil uil-lock-alt"></i>
                                                </div>
                                                <div
                                                    hidden={
                                                        loginError.length === 0
                                                            ? "hidden"
                                                            : ""
                                                    }
                                                    className="mt-2 alert alert-danger alert-dismissible fade show"
                                                >
                                                    <strong>
                                                        {loginError}
                                                    </strong>
                                                </div>
                                                <a
                                                    href="#"
                                                    className="btn mt-4"
                                                    onClick={
                                                        handleLoginFormSubmit
                                                    }
                                                >
                                                    submit
                                                </a>
                                                <p className="mb-0 mt-4 text-center">
                                                    <a
                                                        href="#"
                                                        onClick={() =>
                                                            handleLoginSwitch(1)
                                                        }
                                                        className="link"
                                                    >
                                                        reset password ?
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-back">
                                        <div className="center-wrap">
                                            <div className="section text-center">
                                                <h4 className="mb-4 pb-3">
                                                    Reset your Password
                                                </h4>
                                                <div className="form-group mt-2">
                                                    <input
                                                        type="email"
                                                        name="logemail"
                                                        className="form-style"
                                                        placeholder="Your Email"
                                                        id="logemail"
                                                        autoComplete="off"
                                                        value={resetEmail}
                                                        onChange={(e) => {
                                                            setResetMessage("");
                                                            setResetEmail(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <i className="input-icon uil uil-at"></i>
                                                </div>
                                                <div
                                                    hidden={
                                                        resetMessage.length ===
                                                        0
                                                            ? "hidden"
                                                            : ""
                                                    }
                                                    className="mt-2 alert alert-danger alert-dismissible fade show"
                                                >
                                                    <strong>
                                                        {resetMessage}
                                                    </strong>
                                                </div>
                                                <a
                                                    href="#"
                                                    className="btn mt-4"
                                                    onClick={handleResetEmail}
                                                >
                                                    get reset email
                                                </a>
                                                &nbsp;&nbsp;&nbsp;
                                                <a
                                                    href="#"
                                                    className="btn mt-4"
                                                    onClick={handleResetHint}
                                                >
                                                    get hint
                                                </a>
                                                <p className="mb-0 mt-4 text-center">
                                                    <a
                                                        href="#"
                                                        onClick={() =>
                                                            handleLoginSwitch(0)
                                                        }
                                                        className="link"
                                                    >
                                                        back to login
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
