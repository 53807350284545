import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Context } from "../util/constants";

const Navigation = () => {
    return (
        <Nav
            activeKey="/home"
            // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        >
            <Nav.Item>
                <Nav.Link className="link" href={Context}>
                    Login
                </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
                <Nav.Link className="link" href={`${Context}?reset=1`}>
                    Reset Password
                </Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
                <Nav.Link className="link" href={`${Context}/newpassword`}>
                    New Password
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className="link" href={`${Context}/myplan`}>
                    My Plan
                </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
                    <Nav.Link eventKey="link-1">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>
                        Disabled
                    </Nav.Link>
                </Nav.Item> */}
        </Nav>
    );
};

export default Navigation;
