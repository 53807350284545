import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { RpApi } from "../util/Api";
import { Context } from "../util/constants";
import revplanLogo from "../image/RevisionPlanLogo32.png";

const NewPassword = () => {
    const [urlParams] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [resetMessage, setResetMessage] = useState("");
    const [errorInReset, setErrorInReset] = useState(0);

    const setUrlParams = () => {
        for (let [key, value] of searchParams.entries()) {
            urlParams[key] = value;
        }
        setSearchParams("");
    };

    const validatePassword = () => {
        setResetMessage("");
        if (password === undefined || password.length === 0) {
            setResetMessage("Please enter a New Password");
            return false;
        } else if (password !== password2) {
            setResetMessage("Passwords do not match");
            return false;
        }
        return true;
    };

    const handleResetPassword = () => {
        if (urlParams.key === undefined || urlParams.key.length === 0) {
            setResetMessage("Missing Token - Please use link in email");
            return;
        } else if (!validatePassword()) {
            return;
        }

        let params =
            "action=resetPassword" +
            "&key=" +
            urlParams.key +
            "&pswd=" +
            password;

        RpApi.call("ajaxGeneric", params, (jsonResp) => {
            if (jsonResp.status !== 200) {
                console.log(
                    "Login - handleResetPassword (" +
                        params +
                        ") - Error:" +
                        jsonResp.status +
                        " : " +
                        jsonResp.error
                );
                setResetMessage(jsonResp.error);
            } else {
                let json = JSON.parse(jsonResp.data);
                setErrorInReset(
                    json.error.length > 0 || json.data.indexOf("Success") !== 0
                        ? 1
                        : 0
                );
                setResetMessage(json.error.length > 0 ? json.error : json.data);
            }
        });
    };

    useEffect(() => {
        setUrlParams();
    }, []);

    return (
        <div className="section">
            <div className="container">
                <div className="row full-height justify-content-center">
                    <div className="col-12 text-center align-self-center py-5">
                        <div className="section pb-5 pt-5 pt-sm-2 text-center">
                            <div className="card-3d-wrap mx-auto">
                                <div className="card-3d-wrapper">
                                    <div className="card-front">
                                        <div className="center-wrap">
                                            <div className="section text-center">
                                                <h4 className="mb-4 pb-3">
                                                    Set New Password &nbsp;
                                                    <img src={revplanLogo} />
                                                </h4>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="logpass"
                                                        className="form-style"
                                                        placeholder="New Password"
                                                        id="logpass"
                                                        autoComplete="off"
                                                        value={password}
                                                        onChange={(e) => {
                                                            setResetMessage("");
                                                            setPassword(
                                                                e.target.value
                                                            );
                                                            setPassword2("");
                                                        }}
                                                    />
                                                    <i className="input-icon uil uil-at"></i>
                                                </div>
                                                <div className="form-group mt-2">
                                                    <input
                                                        type="password"
                                                        name="logpassConfirm"
                                                        className="form-style"
                                                        placeholder="Confirm New Password"
                                                        id="logpassconfirm"
                                                        autoComplete="off"
                                                        value={password2}
                                                        onChange={(e) => {
                                                            setResetMessage("");
                                                            setPassword2(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                    <i className="input-icon uil uil-lock-alt"></i>
                                                </div>
                                                <div
                                                    hidden={
                                                        resetMessage.length ===
                                                        0
                                                            ? "hidden"
                                                            : ""
                                                    }
                                                    className="mt-2 alert alert-danger alert-dismissible fade show"
                                                >
                                                    <strong>
                                                        {resetMessage}
                                                    </strong>
                                                </div>
                                                <a
                                                    href="#"
                                                    className="btn mt-4"
                                                    onClick={
                                                        handleResetPassword
                                                    }
                                                >
                                                    submit
                                                </a>
                                                <p className="mb-0 mt-4 text-center">
                                                    <Link
                                                        className="link"
                                                        to={`${Context}?reset=${errorInReset}`}
                                                    >
                                                        back to login
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPassword;
